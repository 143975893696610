import React from 'react'
import { Link } from 'gatsby'
import firebase from '../lib/firebase'
import SEO from '../components/seo.js'
import { parseErrorCode } from '../util'
import monoLogo from '../images/mono-logo.svg'

export default props => {
    const [submitting, setSubmitting] = React.useState(false)
    const [error, setError] = React.useState(null)
    const [submitted, setSubmitted] = React.useState(false)
    const [email, setEmail] = React.useState('')

    const onEmailChange = e => setEmail(e.target.value)

    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitting(true)
        setError(null)
        try {
            await firebase.auth().sendPasswordResetEmail(email)
            setSubmitted(true)
        } catch (err) {
            setError(parseErrorCode(err.code))
        } finally {
            setSubmitting(false)
        }
    }

    const isValidForm = () => email !== ''

    return (
        <div className='fixed inset-0 min-h-screen flex items-center justify-center bg-gray-100'>
            <SEO title='Reset Password' />
            <button onClick={() => window.history.back()} className=''>
                <div className='bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 rounded-full text-gray-600 fixed inset-y-0 h-10 w-10 p-2 ml-4 mt-4 left-0 flex-center'>
                    <svg
                        className='h-full w-full'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                    >
                        <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M10 19l-7-7m0 0l7-7m-7 7h18'
                        />
                    </svg>
                </div>
            </button>
            <div className='max-w-md w-full px-6'>
                <Link
                    to='/'
                    className='flex-center text-gray-900 transform duration-100 scale-100 active:scale-95'
                >
                    <img
                        alt='Mono FM'
                        width='150px'
                        height='100%'
                        className='group-hover:text-teal-600 transform duration-150 group-hover:scale-110'
                        src={monoLogo}
                    />
                </Link>
                {!submitted ? (
                    <>
                        <h2 className='mt-10 text-3xl font-semibold text-center leading-9 font-display'>
                            Reset password
                        </h2>
                        <p className='mt-5 text-sm leading-5 text-center text-gray-600'>
                            Enter your email and we'll send you a link to reset your password.
                        </p>

                        <form className='mt-5' onSubmit={handleSubmit}>
                            <div className='rounded-md shadow-sm'>
                                <input
                                    aria-label='Email address'
                                    name='email'
                                    type='email'
                                    required
                                    className='border-gray-300 placeholder-gray-500 focus:shadow-outline-blue focus:border-blue-300  appearance-none relative block w-full px-3 py-2 border text-gray-900 rounded-md focus:outline-none sm:text-sm sm:leading-5'
                                    placeholder='Email address'
                                    onChange={onEmailChange}
                                    value={email}
                                />
                            </div>

                            <div className='mt-5'>
                                <button
                                    type='submit'
                                    className={`${
                                        submitting || !isValidForm()
                                            ? 'opacity-50 pointer-events-none'
                                            : 'hover:bg-gray-600'
                                    } relative block w-full py-2 px-3 border border-transparent rounded-md text-white font-semibold bg-gray-800 hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95`}
                                >
                                    <span className={`${submitting ? 'opacity-0' : ''} `}>
                                        Send password reset email
                                    </span>
                                    <span
                                        className={`${
                                            submitting ? 'opacity-100' : 'opacity-0'
                                        } absolute inset-0 flex items-center justify-center pointer-events-none `}
                                    >
                                        <svg className='h-8 w-8 spin' viewBox='0 0 24 24'>
                                            <path
                                                className='text-gray-600'
                                                fill='currentColor'
                                                d='M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z'
                                            />
                                            <path
                                                className='text-gray-400'
                                                fill='currentColor'
                                                d='M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z'
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </form>
                        {error && (
                            <p
                                className={`my-2 py-2 px-4 duration-75 transition-opacity shake text-center text-red-700 rounded bg-red-100 font-medium text-sm`}
                            >
                                {error}
                            </p>
                        )}

                        <div className='mt-6'>
                            <div className='relative'>
                                <div className='absolute inset-0 flex items-center'>
                                    <div className='w-full border-t border-gray-300' />
                                </div>
                                <div className='relative flex justify-center text-sm leading-5'>
                                    <span className='px-2 bg-gray-100 text-gray-500'>
                                        Don't have an account?
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <h2 className='mt-10 text-xl font-semibold text-teal-600 text-center leading-9 font-display'>
                        Password Reset Email Sent!
                        <br /> Please check your email
                    </h2>
                )}
                <div className='mt-4'>
                    <a
                        href='/signup'
                        className='block w-full text-center py-2 px-3 border border-gray-300 rounded-md text-gray-900 font-medium hover:border-gray-400 focus:outline-none focus:border-gray-400 sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95'
                    >
                        Go to Signup Page
                    </a>
                </div>
            </div>
        </div>
    )
}
